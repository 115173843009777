import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Grid, Heading, Text, Flex } from "theme-ui";
import CartContext from "../hooks/cartContext";
import CustomerContext from "../hooks/customerContext";
import CustomerTokenContext from "../hooks/customerTokenContext";
import getOrder from "../hooks/getOrder";
import { useClSdk } from "../hooks/useClSdk";
import Layout from "../components/layout";
import CartProduct from "../components/cartProduct";
import { InboundLink, OutboundLink } from "../components/link";
import BagIcon from "../assets/img/icons/carrello.inline.svg";
import CartSkeleton from "../components/skeleton/cart";
import { Helmet } from "react-helmet";
import BouncingDotsLoader from "../components/bouncingDotsLoader";

const CartPage = () => {
  const { customer, setCustomer } = useContext(CustomerContext);
  const { customerToken } = useContext(CustomerTokenContext);
  const { cart, setCart } = useContext(CartContext);
  const [itemQuantity, setItemQuantity] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false);
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(false);
  const cl = useClSdk();
  const freeShipping = 200;

  console.log(customer, cart);

  const updateLineItem = async (quantity, id) => {
    const line_item = {
      id: id,
      quantity: quantity,
      _external_price: true,
    };
    console.log("start request");
    setIsUpdatingQuantity(true);
    await cl.line_items.update(line_item).catch((e) => console.log(e));
    setIsUpdatingQuantity(false);

    getOrder(cl, cart.id)
      .then((value) => {
        setCart(value);
        console.log("end request");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (customer && cart) {
      getOrder(cl, cart.id)
        .then((value) => {
          setCart(value);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [customer]);

  useEffect(() => {
    if (cart && cart.line_items.length > 0) {
      let tmp = 0;
      cart.line_items.map((item, a) => {
        if (item.item_type === "skus") tmp += item.quantity;

        return item;
      }, 0);

      setItemQuantity(tmp);
    }
    // setCartItems([]);
    setCartItems(
      cart && cart.line_items.length > 0
        ? cart.line_items.filter((item) => item.item_type === "skus")
        : []
    );
  }, [cart]);

  console.log(cart);

  const updateQuantity = (quantity, id) => {
    updateLineItem(quantity, id);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowSkeleton(false);
    }, 300);
  }, []);

  useEffect(() => {
    if (customer && cart) {
      console.log("customer and cart");
      console.log(
        customer.metadata.minimum_orde_value,
        cart.subtotal_amount_cents
      );
      const minimumOrderValue = customer.metadata?.minimum_orde_value;
      if (minimumOrderValue && cart.subtotal_amount_cents) {
        console.log(
          cart.subtotal_amount_cents < minimumOrderValue
        );
        setIsCheckoutDisabled(
          cart.subtotal_amount_cents < minimumOrderValue
        );
      } else {
        setIsCheckoutDisabled(false);
      }
    }
  }, [customer, cart]);

  return (
    <Layout>
      <Helmet>
        <title>Carrello | Socaf</title>
      </Helmet>
      <Container>
        {!showSkeleton && cart && cartItems.length > 0 ? (
          <>
            <Grid columns={[1, 1, ".7fr .3fr"]} gap={[0, 5]}>
              <Box>
                <Box>
                  <Heading as="h1" variant="h2" sx={{ color: "primary" }}>
                    Carrello
                  </Heading>
                  {itemQuantity && (
                    <Box
                      sx={{
                        fontSize: [2],
                        fontWeight: "400",
                        pb: [4, 5, 8],
                      }}
                    >
                      <Text color="lightBorder">
                        {`${itemQuantity} articol${
                          itemQuantity > 0 ? "i" : "o"
                        } |`}
                      </Text>
                      <Text>{` ${cart.formatted_subtotal_amount}`}</Text>
                    </Box>
                  )}
                </Box>
                <Grid sx={{ gridTemplateRows: "auto" }} gap={[4, 5, 8]}>
                  {cartItems.map((lineItem, index) => (
                    <Box key={lineItem.id}>
                      <CartProduct
                        sku={lineItem}
                        updateQuantity={updateQuantity}
                      />
                    </Box>
                  ))}
                </Grid>
              </Box>

              <Box>
                <Box>
                  <Heading
                    as="h2"
                    variant="h2"
                    sx={{ color: "primary", mt: [5], pb: [4, 5, 6] }}
                  >
                    Riepilogo ordine
                  </Heading>
                  <Flex
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: [3],
                    }}
                  >
                    <Box>
                      <Text sx={{ fontSize: [1, 5], color: "lightBorder" }}>
                        Costo di spedizione
                      </Text>
                    </Box>
                    <Box
                      sx={{
                        fontSize: [1, 5],
                        color: "lightBorder",
                        fontWeight: "600",
                      }}
                    >
                      {cart.total_amount_float < freeShipping && customer?.metadata?.free_shipping !== true  ? (
                        <Text>da calcolare</Text>
                      ) : (
                        <Text>gratuito</Text>
                      )}
                    </Box>
                  </Flex>
                  <Flex
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: [3],
                    }}
                  >
                    <Text sx={{ fontSize: [1, 5], color: "lightBorder" }}>
                      IVA
                    </Text>
                    <Box
                      sx={{
                        fontSize: [1, 5],
                        color: "lightBorder",
                        fontWeight: "600",
                      }}
                    >
                      da calcolare
                    </Box>
                  </Flex>
                  <Flex
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "start",
                      mb: [4, 5, 6],
                    }}
                  >
                    <Text sx={{ fontSize: [1, 5] }}>Subtotale</Text>
                    <Box
                      sx={{
                        textAlign: "right",
                        fontSize: [1, 5],
                        fontWeight: "600",
                        display: "flex",
                      }}
                    >
                      {isUpdatingQuantity && (
                        <Box sx={{ mr: [4] }}>
                          <BouncingDotsLoader color="primary" />
                        </Box>
                      )}
                      {cart.formatted_subtotal_amount}
                    </Box>
                  </Flex>
                  <Box
                    sx={{
                      fontSize: [1, 2, 2],
                      color: "lightBorder",
                      fontWeight: "400",
                    }}
                  >
                    Prezzo IVA esclusa
                    {cart.total_amount_float < freeShipping && customer?.metadata?.free_shipping !== true && (
                      <Text>, spese di spedizione calcolate al checkout.</Text>
                    )}
                  </Box>
                  {cart.total_amount_float < freeShipping && customer?.metadata?.free_shipping !== true && (
                    <Box
                      sx={{
                        px: [4],
                        py: [2],
                        my: [4],
                        backgroundColor: "green",
                      }}
                    >
                      <Text sx={{ fontSize: [1] }}>
                        Ti mancano{" "}
                        <b>
                          €{(freeShipping - cart.total_amount_float).toFixed(2)}
                        </b>{" "}
                        per avere la spedizione gratuita!
                      </Text>
                    </Box>
                  )}
                  {isCheckoutDisabled &&
                    customer?.metadata?.minimum_orde_value && (
                      <Text
                        as="p"
                        sx={{
                          color: "black",
                          my: [3],
                          fontSize: [1],
                          px: [4],
                          py: [2],
                          backgroundColor: "#eeeeee",
                        }}
                      >
                        L'ordine minimo di €
                        {parseFloat(customer.metadata.minimum_orde_value) / 100}{" "}
                        non è stato raggiunto. Aggiungi altri prodotti al
                        carrello per procedere al checkout.
                      </Text>
                    )}
                  {customerToken && customerToken.access_token && (
                    <Box>
                      <OutboundLink
                        href={`https://checkout.socaf.it/${cart.id}?accessToken=${customerToken.access_token}`}
                        target="_self"
                        variant="buttons.primary"
                        sx={{
                          display: "inline-block",
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                          fontSize: [3],
                          fontWeight: "600",
                          borderRadius: "unset",
                          p: [3],
                          mt: [3],
                          opacity: isCheckoutDisabled ? 0.5 : 1,
                          pointerEvents: isCheckoutDisabled ? "none" : "auto",
                          cursor: isCheckoutDisabled
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        Vai al pagamento
                      </OutboundLink>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </>
        ) : showSkeleton ? (
          <CartSkeleton />
        ) : (
          <>
            <Heading as="h1" variant="h2" sx={{ color: "primary" }}>
              Carrello
            </Heading>
            <Flex
              sx={{
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid",
                color: "lightBorder",
                svg: {
                  color: "lightBorder",
                },
                borderColor: "lightBorder",
                p: [14],
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  pb: [4, 5, 7],
                  svg: {
                    width: "92px",
                    height: "auto",
                    "*": {
                      stroke: "lightBorder",
                    },
                  },
                }}
              >
                <BagIcon />
              </Box>
              <Box>
                <Text sx={{ fontSize: [7] }}>
                  Il tuo carrello è attualmente vuoto!
                </Text>
              </Box>
            </Flex>
          </>
        )}
        <Box>
          <Heading as="h2" variant="h2" sx={{ my: [6], color: "primary" }}>
            Preferiti
          </Heading>
          <Box
            sx={{
              fontWeight: "400",
              fontSize: [5],
              a: {
                color: "dark",
                "&:hover": {
                  color: "primary",
                },
              },
            }}
          >
            <InboundLink to="/account/favourites">
              Vuoi visualizzare i preferiti?{" "}
            </InboundLink>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default CartPage;
